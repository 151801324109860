import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { Provider as JotaiProvider } from "jotai";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import router from "./Router";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'NanumSquareNeoBrg';
    src: url('/NanumSquareNeo-bRg.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  body, html {
    margin: 0;
    padding: 0;
    font-family: 'NanumSquareNeoBrg', sans-serif;
    line-height: 1.7;
    overflow-y: auto;
  }

  textarea , input , button, div, p, span, h1,h2,h3,h4,h5,h6, select,option,li,ul {
    font-family: 'NanumSquareNeoBrg', sans-serif !important;
    line-height: 1.7;
  }

  .canvas {
    height: 100vh;
    left: 0px;
    pointer-events: none;
    position: fixed;
    top: 0px;
    width: 100vw;
  }
`;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <JotaiProvider>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <GlobalStyle />
        <RouterProvider router={router} />
      </LocalizationProvider>
    </JotaiProvider>
  </QueryClientProvider>
);

// ----------- Service Worker 등록 -----------
if ("serviceWorker" in navigator) {
  // 페이지 로드가 완료되면 serviceWorker.js 등록 시도
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("서비스 워커 등록 성공:", registration);

        // [1] 업데이트 감지(onupdatefound)
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker) {
            installingWorker.onstatechange = () => {
              // 새 Service Worker의 상태가 'installed'가 되면,
              // 즉, 이 SW가 완전히 설치되었음을 의미
              if (installingWorker.state === "installed") {
                // 기존 SW가 이미 컨트롤 중이라면(즉, 기존에 등록된 SW가 있다면),
                // 그것은 "새 버전이 배포된 것"임을 의미
                if (navigator.serviceWorker.controller) {
                  // 사용자에게 새 버전이 있다고 알려주고, 새로고침 유도
                  const isConfirm = window.confirm(
                    "새 버전이 배포되었습니다.\n업데이트를 위해 새로고침하시겠습니까?"
                  );
                  if (isConfirm) {
                    // 새로고침
                    window.location.reload();
                  }
                } else {
                  // 처음 설치 시에는 그냥 "처음 설치됨" 로그만
                  console.log("Service Worker가 처음 설치되었습니다.");
                }
              }
            };
          }
        };
      })
      .catch((error) => {
        console.log("서비스 워커 등록 실패:", error);
      });
  });
}
