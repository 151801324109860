import { Box, Button, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import * as S from './styled';
import SendIcon from '@mui/icons-material/Send';
import WarningIcon from '@mui/icons-material/Warning';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { changeBirthdayAtom, changeConfirmPWValueAtom, changeEmailValueAtom, changeEmailVerifyValueAtom, changeNicknameAtom, changePWValueAtom, checkAllValueAtom, checkTypeSelectAtom, onEmailSendClickAtom } from '../../../../ViewModels/SignupViewModel';
import { emailSendingStateAtom, signupModelAtom } from '../../../../Atoms/SignupAtom';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import theme from '../../../../Styles/theme';
import { applyGroupCodeModalClearAtom, applyGroupCodeModalEditGroupCodeAtom, applyGroupCodeModalGetGroupNameAtom, applyGroupCodeModalVerifyAtom, groupCodeInputStateAtom } from '../../../../ViewModels/Signup/ApplyGroupCodeViewModel';
import { useNavigate } from 'react-router-dom';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';

const SignupInputSection = () => {
  const [, checkTypeSelect] = useAtom(checkTypeSelectAtom);
  const [signupModel] = useAtom(signupModelAtom);
  const [emailSendingState] = useAtom(emailSendingStateAtom);
  const [, sendEmail] = useAtom(onEmailSendClickAtom);
  const [, changeEmailValue] = useAtom(changeEmailValueAtom);
  const [, changeEmailVerifyValue] = useAtom(changeEmailVerifyValueAtom);
  const [, changePWValue] = useAtom(changePWValueAtom);
  const [, changeConfirmPWValue] = useAtom(changeConfirmPWValueAtom);
  const [, changeNickname] = useAtom(changeNicknameAtom);
  const [, changeBirthday] = useAtom(changeBirthdayAtom);
  const [, checkAll] = useAtom(checkAllValueAtom);
  const [groupCode, setGroupCode] = useAtom(applyGroupCodeModalEditGroupCodeAtom);
  const verifyCode = useSetAtom(applyGroupCodeModalVerifyAtom);
  const groupCodeState = useAtomValue(groupCodeInputStateAtom);
  const groupName = useAtomValue(applyGroupCodeModalGetGroupNameAtom);
  const groupCodeClear = useSetAtom(applyGroupCodeModalClearAtom);
  const muiTheme = useTheme();
  const isXs = useMediaQuery(muiTheme.breakpoints.down('md'));
  const navigate = useNavigate();

  const userTypes = [
    { type: "mentee", value: "멘티" },
    // { type: "mentor", value: "멘토" },
    { type: "teacher", value: "교사" },
  ]

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "50px",
        boxSizing: "border-box",
        flex: 1,
        overflowY: "auto",
        overflowX: "hidden",
        gap: "20px",
        "@media (max-width: 768px)": {
          padding: "20px",
          gap: "10px",
        },
        position: "relative",
      }}
    >
      <div style={{ height: isXs ? "30px" : "50px" }}></div>
      {
        isXs &&
        <Stack direction="row" justifyContent={"start"} width={"100%"} position={"absolute"} left={"30px"} top={"30px"} sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          width: "100%",
          position: "absolute",
          left: "30px",
          top: "30px",

          "@media (max-width: 768px)": {
            left: "10px",
            top: "10px",
          },
        }}>
          <Button
            sx={{
              color: process.env.REACT_APP_MAIN_COLOR,
              "&:hover": {
                color: process.env.REACT_APP_MAIN_COLOR,
              },
            }}
            onClick={() => navigate("/")}
          >
            <ArrowBackIosNew />
            로그인으로
          </Button>
        </Stack>
      }
      <S.CustomStack>
        {!isXs && ( // isXs가 false일 때만 Typography를 렌더링
          <Typography
            sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
          >
            그룹 코드 입력(선택)
          </Typography>
        )}

        {
          groupCodeState ?
            <S.InputContainer>
              <S.CustomInputBase
                value={groupName}
                disabled
              />
              <S.CustomButton variant="contained" onClick={groupCodeClear}>
                재입력
              </S.CustomButton>
            </S.InputContainer>
            : <>
              <S.InputContainer>
                <S.CustomInputBase
                  value={groupCode}
                  onChange={(e) => setGroupCode(e.target.value)}
                  placeholder={isXs ? "그룹 코드 입력(선택)" : undefined}
                />
                <S.CustomButton variant="contained" onClick={verifyCode} disabled={groupCode === ""}>
                  등록
                </S.CustomButton>
              </S.InputContainer>
            </>
        }
        {
          !signupModel.checkGroupCode && (
            <Typography sx={{ flex: 1, color: "red", fontSize: isXs ? "11px" : "13px" }}>
              그룹 코드를 입력하지 않으셔도 회원가입이 가능합니다.
            </Typography>
          )
        }
      </S.CustomStack>
      {
        signupModel.checkGroupCode &&
        <S.CustomStack>
          <Typography sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}>유저 유형 선택</Typography>
          <Stack direction={"row"} sx={{ width: "100%", gap: "20px" }}>
            {userTypes.map((userType, index) =>
              <Button
                key={index}
                variant='contained'
                sx={{
                  flex: 1,
                  background:
                    signupModel.userType === userType.type
                      ? process.env.REACT_APP_MAIN_COLOR
                      : "transparent",
                  color:
                    signupModel.userType === userType.type
                      ? "white"
                      : "#c0c0c0",
                  borderRadius: "20px",
                  "&:hover": {
                    background: process.env.REACT_APP_MAIN_COLOR,
                    color: "white",
                  },
                }}
                onClick={() => checkTypeSelect(userType.type)}
              >
                {userType.value}
              </Button>
            )}
          </Stack>
        </S.CustomStack>
      }
      <S.CustomStack>
        {
          !isXs &&
          <Typography
            sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
          >
            이메일 입력
          </Typography>
        }
        <S.InputContainer>
          <S.CustomInputBase
            value={signupModel.email}
            onChange={changeEmailValue}
            placeholder={isXs ? "이메일 입력" : undefined}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendEmail();
              }
            }}
          />
          <S.CustomIconButton onClick={sendEmail} tabIndex={-1}>
            {signupModel.checkEmail ? (
              signupModel.realEmailVerifyNum !== "" ? (
                <Typography
                  sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                >
                  재전송
                </Typography>
              ) : (
                <SendIcon
                  sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                />
              )
            ) : (
              <Tooltip title="이메일이 아닙니다.">
                <WarningIcon sx={{ color: "red" }} />
              </Tooltip>
            )}
          </S.CustomIconButton>
        </S.InputContainer>
        {!signupModel.checkDupEmail && emailSendingState !== "" && (
          <Typography
            sx={{ flex: 1, color: "red", fontSize: isXs ? "11px" : "13px" }}
          >
            가입된 이메일이 있습니다.변경 후 다시 시도해 주세요.
          </Typography>
        )}
      </S.CustomStack>

      {signupModel.realEmailVerifyNum !== "" && (
        <S.CustomStack>
          {
            !isXs &&
            <Typography
              sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
            >
              이메일 인증번호
            </Typography>
          }
          <S.InputContainer>
            <S.CustomInputBase
              value={signupModel.userInputEmailVerifyNum}
              onChange={changeEmailVerifyValue}
              placeholder={isXs ? "이메일 인증번호" : undefined}
            />
            <S.CustomIconButton tabIndex={-1}>
              {signupModel.checkuserInputEmailVerifyNum ? (
                <CheckCircleSharpIcon
                  sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                />
              ) : (
                <Tooltip title="이메일 인증번호가 다릅니다.">
                  <WarningIcon sx={{ color: "red" }} />
                </Tooltip>
              )}
            </S.CustomIconButton>
          </S.InputContainer>
        </S.CustomStack>
      )}

      {
        !(signupModel.checkuserInputEmailVerifyNum === true &&
          signupModel.userInputEmailVerifyNum === "") &&
        <>
          <S.CustomStack>
            {
              !isXs &&
              <Typography
                sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
              >
                비밀번호 입력
              </Typography>
            }
            <S.InputContainer>
              <S.CustomInputBase
                type="password"
                value={signupModel.password}
                onChange={changePWValue}
                placeholder={isXs ? "비밀번호 입력" : undefined}
              />
              <S.CustomIconButton tabIndex={-1}>
                {signupModel.checkPassword ? (
                  <CheckCircleSharpIcon
                    sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                  />
                ) : (
                  <Tooltip title="비밀번호는 최소 8자 이상이어야 하며, 대문자, 숫자, 특수문자를 포함해야 합니다.">
                    <WarningIcon sx={{ color: "red" }} />
                  </Tooltip>
                )}
              </S.CustomIconButton>
            </S.InputContainer>
          </S.CustomStack>

          <S.CustomStack>
            {
              !isXs &&
              <Typography
                sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
              >
                비밀번호 확인
              </Typography>
            }
            <S.InputContainer>
              <S.CustomInputBase
                type="password"
                value={signupModel.confirmPassword}
                onChange={changeConfirmPWValue}
                placeholder={isXs ? "비밀번호 확인" : undefined}
              />
              <S.CustomIconButton tabIndex={-1}>
                {signupModel.checkconfirmPassword ? (
                  <CheckCircleSharpIcon
                    sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
                  />
                ) : (
                  <Tooltip title="비밀번호와 동일해야 합니다.">
                    <WarningIcon sx={{ color: "red" }} />
                  </Tooltip>
                )}
              </S.CustomIconButton>
            </S.InputContainer>
          </S.CustomStack>
        </>
      }

      <S.CustomStack>
        {
          !isXs &&
          <Typography
            sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
          >
            닉네임
          </Typography>
        }
        <S.InputContainer>
          <S.CustomInputBase
            type="text"
            value={signupModel.nickname}
            onChange={changeNickname}
            placeholder={isXs ? "닉네임 입력" : undefined}
          />
          <S.CustomIconButton tabIndex={-1}>
            {signupModel.checkNickname ? (
              <CheckCircleSharpIcon
                sx={{ color: process.env.REACT_APP_MAIN_COLOR }}
              />
            ) : (
              <Tooltip title="사용할 닉네임을 입력해 주세요.">
                <WarningIcon sx={{ color: "red" }} />
              </Tooltip>
            )}
          </S.CustomIconButton>
        </S.InputContainer>
      </S.CustomStack>

      <S.CustomStack>
        <Typography
          sx={{ flex: 1, color: "#8f8f8f", fontSize: "13px" }}
        >
          생년월일(선택)
        </Typography>
        <DesktopDatePicker
          value={dayjs(signupModel.birthDay)}
          onChange={(value) => {
            if (value) {
              changeBirthday(value);
            }
          }}
          sx={{
            width: "100%",
            ".MuiInputBase-root": {
              height: "52px",
              borderRadius: "31px",
              border: `1px solid ${process.env.REACT_APP_MAIN_COLOR}`,
              boxSizing: "border-box",
            },
            ".MuiOutlinedInput-root": {
              padding: "0 30px 0 10px",
            },
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
          defaultValue={dayjs("2022-04-17")}
        />
      </S.CustomStack>
      <S.WrapperButton onClick={checkAll}>Sign Up</S.WrapperButton>
    </Box>
  );
};

export default SignupInputSection;
