import { atom } from "jotai";
import { setCookie } from "../Utils/CookieUtil";
import { loginValueAtom } from "../Atoms/LoginAtom";
import { errorModalAtom, successModalAtom } from "../Atoms/RootAtom";
import { initialSignupModelAtom, signupModelAtom } from "../Atoms/SignupAtom";
import { login } from "../Queries/Loginqueries";
import { isFromLinkAtom, referrerAtom } from "../Atoms/PublicAtom";
import { needToChangePasswordModalAtom } from "../Atoms/Login/NeedToChangePasswordModalAtom";
import { devConsoleLog } from "../Utils/ConsoleLogInDevelopment";

export const onLoginClickAtom = atom(null, async (get, set, { navigate }) => {
  try {
    const { username, password } = get(loginValueAtom);

    if (username === "" || password === "") {
      set(errorModalAtom, {
        state: true,
        redirectUrl: "",
        text: "아이디나 패스워드를 입력해주세요",
        title: "입력 없음",
        event: null,
        eventText: "",
      });
    } else {
      const response = await login(username, password);

      devConsoleLog("response", response.data);

      if (response.data.results === 1) {
        const accessToken = response.data.access_token as string;
        const refreshToken = response.data.refresh_token as string;
        if (response.data.user_info.is_admin_made as boolean) {
          set(needToChangePasswordModalAtom, (prev) => ({
            ...prev,
            open: true,
            accessToken: accessToken,
            refreshToken: refreshToken,
          }));
          return;
        }

        // 쿠키에 리프레시 토큰 저장
        setCookie(
          process.env.REACT_APP_USER_REFRESH_COOKIE_ID,
          response.data.refresh_token,
          {
            path: "/",
            sameSite: "Lax",
            domain: process.env.REACT_APP_DOMAIN_NAME,
            secure: true,
          }
        );

        // 쿠키에 액세스 토큰 저장
        setCookie(
          process.env.REACT_APP_USER_ACCESS_COOKIE_ID,
          response.data.access_token,
          {
            path: "/",
            sameSite: "Lax",
            domain: process.env.REACT_APP_DOMAIN_NAME,
            secure: true,
          }
        );

        // 로그인 아톰 초기화
        set(loginValueAtom, {
          username: "",
          password: "",
        });

        const referrer = get(referrerAtom);

        if (referrer?.includes(process.env.REACT_APP_PAY_ADDRESS)) {
          window.location.href = process.env.REACT_APP_PAY_ADDRESS;
        } else {
          window.location.href = process.env.REACT_APP_PARENT_DASHBOARD_ADDRESS;
        }

        // set(successModalAtom, {
        //   state: true,
        //   event: () =>
        //     (window.location.href =
        //       process.env.REACT_APP_PARENT_DASHBOARD_ADDRESS),
        //   eventText: "커리어 플랜 만들러 가기",
        //   redirectUrl: "",
        //   text: "로그인에 성공하였습니다.\n",
        //   title: "로그인 성공",
        //   isClose: true,
        // });

        // Navigate back to the previous page
        // window.location.href = process.env.REACT_APP_PARENT_DASHBOARD_ADDRESS;
      } else if (response.data.results === -1) {
        set(errorModalAtom, {
          state: true,
          redirectUrl: "",
          text: "아이디나 패스워드가 일치하지 않습니다.\n다시 시도해주세요.",
          title: "아이디 패스워드 오류",
          event: null,
          eventText: "",
        });

        set(loginValueAtom, {
          username: "",
          password: "",
        });
      } else if (response.data.results === -2) {
        const loginValue = get(loginValueAtom);

        set(errorModalAtom, {
          state: true,
          redirectUrl: "",
          text: "저희 사이트는 아직 회원가입을 안하셨네요.\n동일한 이메일로 회원가입하셔서 이용해주세요.",
          title: "서비스 연동",
          event: () => {
            set(isFromLinkAtom, true);

            // set(signupModelAtom, initialSignupModelAtom);

            set(signupModelAtom, {
              userType: "free",
              groupCode: "",
              checkGroupCode: false,
              email: loginValue.username,
              checkEmail: true,
              checkDupEmail: true,
              userInputEmailVerifyNum: "",
              realEmailVerifyNum: "",
              checkuserInputEmailVerifyNum: true,
              password: loginValue.password,
              checkPassword: true,
              confirmPassword: loginValue.password,
              checkconfirmPassword: true,
              nickname: "",
              birthDay: null,
              checkNickname: true,
              allPass: false,
            });

            navigate("/signup");

            set(errorModalAtom, {
              state: false,
              redirectUrl: "",
              text: "저희 사이트는 아직 회원가입을 안하셨네요.\n동일한 이메일로 회원가입하셔서 이용해주세요.",
              title: "서비스 연동",
              event: null,
              eventText: "",
            });
          },
          eventText: "연동",
        });
      } else if (response.data.results === -3) {
        set(errorModalAtom, {
          state: true,
          redirectUrl: "",
          text: "네트워크 오류가 발생했습니다.\n잠시 뒤에 시도해주세요.",
          title: "네트워크 오류",
          event: null,
          eventText: "",
        });
      } else if (response.data.results === -4) {
        // 쿠키에 액세스 토큰 저장
        setCookie(
          process.env.REACT_APP_USER_REFRESH_COOKIE_ID,
          response.data.refresh_token,
          {
            path: "/",
            sameSite: "Lax",
            domain: process.env.REACT_APP_DOMAIN_NAME,
            secure: true,
          }
        );

        // 쿠키에 액세스 토큰 저장
        setCookie(
          process.env.REACT_APP_USER_ACCESS_COOKIE_ID,
          response.data.access_token,
          {
            path: "/",
            sameSite: "Lax",
            domain: process.env.REACT_APP_DOMAIN_NAME,
            secure: true,
          }
        );

        // 로그인 아톰 초기화
        set(loginValueAtom, {
          username: "",
          password: "",
        });
        set(errorModalAtom, {
          state: true,
          redirectUrl: "",
          text: "작업하신 것들이 있지만, 플랜 기간이 모두 만료되었습니다.\n플랜 연장 버튼을 눌러 플랜을 연장해 주세요.",
          title: "플랜 만료",
          event: () =>
            (window.location.href =
              process.env.REACT_APP_PARENT_DASHBOARD_ADDRESS),
          eventText: "플랜 연장",
        });
      } else if (response.data.results === -5) {
        set(errorModalAtom, {
          state: true,
          redirectUrl: "",
          text: "패스워드가 틀렸습니다.",
          title: "패스워드 오류",
          event: null,
          eventText: "",
        });

        set(loginValueAtom, (current) => ({
          ...current,
          password: "",
        }));
      }
    }
  } catch (error) {
    set(errorModalAtom, {
      state: true,
      redirectUrl: "",
      text: "네트워크 오류가 발생했습니다.\n잠시 뒤에 시도해주세요.",
      title: "네트워크 오류",
      event: null,
      eventText: "",
    });
  }
});

// export const onLoginClickAtom = atom(null, async (get, set, { navigate }) => {
//   // Accept navigate function as an argument
//   try {
//     const { username, password } = get(loginValueAtom);

//     if (username === "" || password === "") {
//       set(errorModalAtom, {
//         state: true,
//         redirectUrl: "",
//         text: "아이디나 패스워드를 입력해주세요",
//         title: "입력 없음",
//         event: null,
//         eventText: "",
//       });
//     } else {
//       const response = await axios.post(
//         `${process.env.REACT_APP_USER_API_ADDRESS}/mysql/token/`,
//         {
//           username: username,
//           password: password,
//           site_name: process.env.REACT_APP_SITE_NAME,
//         }
//       );

//       const { refresh, result } = response.data;

//       if (result === 1) {
//         // JWT 디코딩하여 사용자 정보 추출
//         const decodedRefreshToken = jwtDecode(refresh) as any;

//         // set(successModalAtom, {
//         //   state: true,
//         //   redirectUrl: "",
//         //   text: "가입 해주셔서 감사해요~~.정식버전이 나오면 메일로 연락드릴께요.",
//         //   title: "가입 완료",
//         //   event: null,
//         //   eventText: "",
//         // });

//         // 쿠키에 액세스 토큰 저장
//         setCookie(process.env.REACT_APP_USER_COOKIE_ID, refresh, {
//           path: "/",
//           sameSite: "Lax",
//           expires: new Date(decodedRefreshToken.exp * 1000),
//           domain: process.env.REACT_APP_DOMAIN_NAME,
//           secure: true,
//         });

//         // 로그인 아톰 초기화
//         set(loginValueAtom, {
//           username: "",
//           password: "",
//         });

//         // Navigate back to the previous page
//         window.location.href = process.env.REACT_APP_PARENT_DASHBOARD_ADDRESS;
//       } else if (response.data.result === -1) {
//         set(errorModalAtom, {
//           state: true,
//           redirectUrl: "",
//           text: "아이디나 패스워드가 일치하지 않습니다.\n다시 시도해주세요.",
//           title: "아이디 패스워드 오류",
//           event: null,
//           eventText: "",
//         });

//         set(loginValueAtom, {
//           username: "",
//           password: "",
//         });
//       } else if (response.data.result === -2) {
//         set(errorModalAtom, {
//           state: true,
//           redirectUrl: "",
//           text: "저희 사이트는 아직 회원가입을 안하셨네요.\n동일한 이메일로 회원가입하셔서 이용해주세요.",
//           title: "회원가입 오류",
//           event: () => {
//             set(agreeMentModalAtom, true);
//             set(errorModalAtom, {
//               state: false,
//               redirectUrl: "",
//               text: "저희 사이트는 아직 회원가입을 안하셨네요.\n동일한 이메일로 회원가입하셔서 이용해주세요.",
//               title: "회원가입 오류",
//               event: null,
//               eventText: "",
//             });
//           },
//           eventText: "연동",
//         });
//       } else if (response.data.result === -3) {
//         set(errorModalAtom, {
//           state: true,
//           redirectUrl: "",
//           text: "네트워크 오류가 발생했습니다.\n잠시 뒤에 시도해주세요.",
//           title: "네트워크 오류",
//           event: null,
//           eventText: "",
//         });
//       } else if (response.data.result === -4) {
//         set(errorModalAtom, {
//           state: true,
//           redirectUrl: "",
//           text: "작업하신 것들이 있지만, 플랜 기간이 모두 만료되었습니다.",
//           title: "플랜 만료",
//           event: null,
//           eventText: "",
//         });
//       }
//     }
//   } catch (error) {
//     set(errorModalAtom, {
//       state: true,
//       redirectUrl: "",
//       text: "네트워크 오류가 발생했습니다.\n잠시 뒤에 시도해주세요.",
//       title: "네트워크 오류",
//       event: null,
//       eventText: "",
//     });
//   }
// });
